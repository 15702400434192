.custom-section {
  /* ファーストビュー */
  .firstview {
    background-color: #123456;
  }

  .firstContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 12rem;
    padding-bottom: 4rem;
    width: 80%;
  }

  .firstTitleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  .firstTitle {
    flex-basis: 50%;
    text-align: left;
  }

  .firstTitle p {
    font-size: 24px;
    font-weight: bold;
    background: -webkit-linear-gradient(
      135deg,
      #7ee8ff 0%,
      #7ee8ff 20%,
      #7ee8ff 40%,
      #6ce0ec 60%,
      #4ad1c9 80%,
      #4ad1c9 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
    word-wrap: break-word;
    word-break: keep-all;
  }

  .firstTitle h1 {
    font-size: 48px;
    color: white;
    font-weight: 800;
    margin-bottom: 2rem;
  }

  .firstButton {
    display: inline-block;
    padding: 1rem 3rem;
    background: white;
    color: #132b4d;
    border: 2px solid white;
    border-radius: 50px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 600;
    font-size: 14px;
  }

  .firstButton:hover {
    background-color: #132b4d;
    color: white;
  }

  .firstImage {
    flex-basis: 50%;
    text-align: center;
  }

  .firstImage img {
    border-radius: 16px;
  }

  .firstCardSection {
    display: flex;
    justify-content: space-between;
  }

  .firstCard {
    flex-basis: calc(33% - 1rem);
    position: relative;
    padding-left: 32px;
    border-left: 1.5px solid #718094;
    margin: 2rem 0;
  }

  .firstCard-icon {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    margin-bottom: 1rem;
  }

  .firstCard h2 {
    font-weight: 600;
    font-size: 20px;
    color: #fdfefe;
    margin: 1rem 0;
  }

  .firstCard p {
    font-weight: 400;
    font-size: 14px;
    color: #fdfefe;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  /* タブレット */
  @media screen and (max-width: 1000px) {
    .firstTitleSection {
      display: block;
      align-items: center;
    }
    .firstTitle {
      margin-bottom: 32px;
    }
    .firstTitle p {
      font-size: 16px;
    }
    .firstTitle h1 {
      font-size: 32px;
    }
    .firstCardSection {
      flex-direction: column;
      align-items: center;
    }
    .firstCard {
      padding-left: 0;
      margin-bottom: 2rem;
    }
    .firstCardContent {
      margin-left: 16px;
    }
    .firstCard-icon {
      position: relative;
      left: auto;
      top: auto;
      margin-bottom: 1rem;
    }
    .firstCard h2 {
      font-size: 16px;
    }
  }

  /* スマホ */
  @media screen and (max-width: 600px) {
    .firstContainer {
      width: 90%;
    }
  }

  /* セカンドビュー */
  .secondview {
    width: 80%;
    padding-top: 8rem;
    padding-bottom: 8rem;
    margin: 0 auto;
    max-width: 1200px;
  }

  .secondContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .secondTitleCard {
    width: 31%;
    margin: 10px 0;
  }

  .secondeGradientBorderButton {
    margin-top: 64px;

    font-weight: 600;
    font-size: 14px;
    position: relative;
    display: inline-block;
    padding: 12px 28px;
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .secondeGradientBorderButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    border: 3px solid transparent;
    background-image: linear-gradient(
      135deg,
      #be72ff 0%,
      #5f8aff 25%,
      #00a3ff 50%,
      #1cbad0 75%,
      #37d1a1 100%
    );
    background-origin: border-box;
    background-clip: border-box;
    -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    -webkit-mask-clip: padding-box, border-box;
    -webkit-mask-composite: destination-out;
    mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    mask-clip: padding-box, border-box;
    mask-composite: exclude;
  }

  .secondeTitle {
    font-weight: bold;
    font-size: 32px;
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .secondTitleDescription {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 32px;
  }

  .secondCard {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 31%;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
  }

  .secondCardTextSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding-top: 16px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .secondCardContents {
    background-color: #ffffff;
  }

  .secondCardContents img {
    height: 35px;
    width: 35px;
    max-width: 35px;
    max-height: 35px;
    border-radius: 50%;
  }

  .secondCardContents h2 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  .secondCardContents p {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 3rem;
  }

  .firstLinkContent span {
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
  }

  .firstLinkContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .firstLinkContent img {
    margin-right: 8px;
    height: 24px;
    width: 24px;
  }

  .secondCardImageSection {
    background-color: #f2f2f2;
  }

  .secondCardImageSection img {
    width: 100%;
    display: block;
  }

  /* タブレット */
  @media screen and (max-width: 1000px) {
    .secondContainer {
      justify-content: center;
    }
    .secondeTitle {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .secondTitleCard,
    .secondCard {
      width: 100%;
    }
  }
  /* スマホ */
  @media screen and (max-width: 600px) {
    .secondview {
      width: 90%;
    }
    .secondTitleCard,
    .secondCard {
      width: 100%;
    }
  }

  /* サードビュー */
  .thirdview {
    width: 80%;
    padding-top: 8rem;
    padding-bottom: 8rem;
    margin: 0 auto;
    max-width: 1200px;
  }

  .thirdTitleSction {
    width: 70%;
  }

  .thirdGradientBorderButton {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    display: inline-block;
    padding: 12px 28px;
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .thirdGradientBorderButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    border: 3px solid transparent;
    background-image: linear-gradient(
      135deg,
      #be72ff 0%,
      #5f8aff 25%,
      #00a3ff 50%,
      #1cbad0 75%,
      #37d1a1 100%
    );
    background-origin: border-box;
    background-clip: border-box;
    -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    -webkit-mask-clip: padding-box, border-box;
    -webkit-mask-composite: destination-out;
    mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    mask-clip: padding-box, border-box;
    mask-composite: exclude;
  }

  .thirdTitle {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 32px;
  }

  .thirdDescription {
    width: 90%;
    font-weight: normal;
    font-size: 16px;
  }

  .thirdContents {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 16px;
  }

  .thirdCardSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 50%;
  }

  .thirdCard {
    max-width: 95%;
    background-color: transparent;
  }

  .thirdCard-icon {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    margin-bottom: 2rem;
    border-radius: 8px;
  }

  .thirdCard h2 {
    font-weight: 600;
    font-size: 16px;
    color: #132b4d;
    margin-bottom: 8px;
  }

  .thirdCard p {
    font-weight: normal;
    font-size: 14px;
    color: #718094;
  }

  .thirdImage {
    width: 50%;
  }

  .thirdImage img {
    border-radius: 16px;
  }

  @media screen and (max-width: 1000px) {
    .thirdTitleSction {
      width: 100%;
      margin-bottom: 64px;
    }
    .thirdContents {
      flex-direction: column-reverse;
      align-items: center;
      margin: 0;
    }
    .thirdDescription {
      width: 100%;
    }
    .thirdCardSection {
      max-width: 100%;
    }
    .thirdImage {
      margin-top: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 64px;
    }
  }

  /* スマホ */
  @media screen and (max-width: 600px) {
    .thirdview {
      width: 90%;
    }
    .thirdCardSection {
      grid-template-columns: 1fr;
    }
    .thirdCard-icon {
      width: 24px;
      height: 24px;
      max-width: 24px;
      max-height: 24px;
    }
  }

  /* フォースビュー */
  .fourthview {
    width: 80%;
    padding-top: 8rem;
    padding-bottom: 8rem;
    margin: 0 auto;
    max-width: 1200px;
  }

  .fourthTitleSction {
    width: 70%;
    margin-bottom: 32px;
  }

  .fourthGradientBorderButton {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    display: inline-block;
    padding: 12px 28px;
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .fourthGradientBorderButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    border: 3px solid transparent;
    background-image: linear-gradient(
      135deg,
      #be72ff 0%,
      #5f8aff 25%,
      #00a3ff 50%,
      #1cbad0 75%,
      #37d1a1 100%
    );
    background-origin: border-box;
    background-clip: border-box;
    -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    -webkit-mask-clip: padding-box, border-box;
    -webkit-mask-composite: destination-out;
    mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    mask-clip: padding-box, border-box;
    mask-composite: exclude;
  }

  .fourthTitle {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 32px;
  }

  .fourthDescription {
    width: 90%;
    font-weight: normal;
    font-size: 16px;
  }

  .fourthContents {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .fourthImageSection {
    width: 45%;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }

  .fourthImageSection img {
    border-radius: 16px;
  }

  .fourthCardSection {
    width: 55%;
  }

  .fourthCard {
    display: flex;
    position: relative;
    margin-left: 30px;
  }

  .fourthCard-last {
    display: flex;
    position: relative;
    margin-left: 30px;
  }

  .fourthCard::before {
    content: "";
    position: absolute;
    left: 32px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #f8fafc;
  }

  .fourthIconPart {
    min-width: 64px;
  }

  .fourthCard-icon {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 64px;
    height: 64px;
    max-width: 64px;
    max-height: 64px;
    border-radius: 50%;
    background-color: #ffffff;
  }

  .fourthTextPart {
    margin-left: 16px;
  }

  .fourthTextPart h2 {
    color: #132b4d;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .fourthTextPart p {
    color: #718094;
    font-weight: regular;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    display: inline-block;
    margin-bottom: 30px;
  }

  /* タブレット */
  @media screen and (max-width: 1000px) {
    .fourthTitleSction {
      width: 100%;
      margin-bottom: 24px;
    }
    .fourthContents {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
    .fourthTitle {
      margin-bottom: 24px;
    }
    .fourthDescription {
      width: 100%;
    }
    .fourthImageSection {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .fourthCardSection {
      width: 100%;
    }
    .fourthIconPart {
      min-width: 56px;
    }

    .fourthCard-icon {
      width: 56px;
      height: 56px;
      max-width: 56px;
      max-height: 56px;
    }
    .fourthCard::before {
      left: 28px;
    }
  }

  /* スマホ */
  @media screen and (max-width: 600px) {
    .fourthview {
      width: 90%;
    }
    .fourthCard {
      margin-left: 0;
      margin-right: 0;
    }
    .fourthCard-last {
      margin-left: 0;
      margin-right: 0;
    }
    .fourthTextPart {
      margin-left: 10px;
    }
  }
}

.sectionInner {
  max-width: 120rem;
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.helpfulGuide {
  padding: 16rem 0;
}

.helpfulGuideMessage {
  margin-top: 2.4rem;
  margin-bottom: 6.4rem;
}

.articleLink {
  margin-bottom: 3.6rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--green);
}
.articleLink::after {
  content: ">>";
  margin-left: 3rem;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.2em;
  font-family: monospace;
}

.helpfulGuideBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.helpfulGuideLeft {
  max-width: 92.8rem;
  margin-right: 3rem;
  width: 100%;
}

.helpfulGuideRight {
  width: 39rem;
  flex-shrink: 0;
  margin-top: 3rem;
  background-color: var(--white2);
  padding: 3rem 3rem 5rem;
  border-radius: 1rem;
}

.postList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.postListItem {
  width: 32%;
  margin-bottom: 1.4%;
  border: 1px solid var(--grey16);
  border-radius: 0.8rem;
}
.postListItem > a {
  padding: 2rem 1.6rem;
}
.postListItem h3 {
  margin-bottom: 0.8rem;
  margin-top: 1.2rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.postListItem p {
  font-size: 1.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.popularPostsTitle {
  margin-bottom: 1.8rem;
  padding-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.popularPost:not(:last-of-type) {
  margin-bottom: 1.8rem;
}
.popularPost:nth-of-type(1) .popularPostLink::before {
  content: "1";
  background: linear-gradient(to bottom, #d0a900, #fff9e6);
}
.popularPost:nth-of-type(2) .popularPostLink::before {
  content: "2";
  background: linear-gradient(to bottom, #bdc3c9, #eeeeee);
}
.popularPost:nth-of-type(3) .popularPostLink::before {
  content: "3";
}
.popularPost:nth-of-type(4) .popularPostLink::before {
  content: "4";
}
.popularPost:nth-of-type(5) .popularPostLink::before {
  content: "5";
}

.popularPostLink {
  display: flex;
}
.popularPostLink::before {
  content: "1";
  flex-shrink: 0;
  margin-right: 1.4rem;
  width: 2.2rem;
  height: 2.2rem;
  padding-top: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #815a2b, #eeeeee);
  font-family: var(--sub-font);
  font-size: 1.5rem;
  font-weight: bold;
  transform: translateY(1.8rem);
}

.popularPostImageWrap {
  width: 10rem;
  height: 5.625rem;
  margin-right: 1.8rem;
  position: relative;
  flex-shrink: 0;
}
.popularPostImageWrap img {
  -o-object-fit: cover;
  object-fit: cover;
}

.popularPostTitle {
  font-size: 1.4rem;
}

.popularPostDate {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: var(--grey3);
}

.voice {
  padding: 8rem 0;
}

.voiceMessage {
  margin-top: 2.4rem;
}

.caseSpecial {
  width: 100%;
  padding: 2.4rem;
  background-color: var(--grey17);
}

.caseSpecialTop {
  margin-bottom: 5.6rem;
  display: flex;
  align-items: flex-start;
}

.caseSpecialImageWrap {
  width: 40%;
  padding-top: 25%;
  margin-right: 2.4rem;
  border-radius: 0.8rem;
  overflow: hidden;
  position: relative;
}

.caseSpecialCompany {
  margin-bottom: 2.4rem;
  font-size: 2rem;
  font-weight: 500;
}

.caseSpecialHeading {
  margin-bottom: 2rem;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 4.8rem;
}

.caseSpecialBottom {
  display: flex;
  justify-content: space-between;
}

.caseSpecialBottoItem {
  width: 48%;
  display: flex;
}

.caseSpecialBottoTitle {
  width: 9rem;
  height: 9.7rem;
  margin-right: 1.6rem;
  background-color: white;
  border-radius: 0.4rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.caseSpecialBottoTitle img {
  margin-bottom: 1rem;
}

.caseList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.caseList::after {
  content: "";
  width: 27.4305555556%;
}

.caseListItem {
  margin-top: 4rem;
  width: 27.4305555556%;
  border: 1px solid var(--grey16);
  border-radius: 0.8rem;
  overflow: hidden;
}

.caseListItemTexts {
  padding: 0.8rem;
}

.caseListItemHeading {
  margin-top: 1.2rem;
  margin-bottom: 1.4rem;
  font-weight: bold;
}

.caseListItemText {
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
  line-height: 2;
}

.caseListItemBottom {
  padding-top: 1.2rem;
  padding-bottom: 4.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.9;
  color: var(--black5);
  border-top: 1px solid #eee;
}

.caseListItemBottomCompany {
  margin-bottom: 0.6rem;
  font-size: 1.5rem;
}

.caseListItemBottomItem {
  display: flex;
  align-items: flex-start;
}
.caseListItemBottomItem dt {
  flex-shrink: 0;
  margin-right: 1rem;
}
.caseListItemBottomItem dt::after {
  content: "：";
}

.caseLink {
  width: 23.4rem;
  height: 6.4rem;
  margin: 4rem auto 0;
  border-radius: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--blue2);
  border: 1px solid;
}

/* 1000pxと600pxをブレイクポイントとしていますがもう少し細かく設定していただいても大丈夫です */
/* タブレット */
@media screen and (max-width: 1000px) {
  .helpfulGuideBox {
    flex-direction: column;
    align-items: center;
  }
  .helpfulGuideLeft {
    margin-right: 0;
  }
}
/* スマホ */
@media screen and (max-width: 600px) {
  .sectionInner {
    width: 90%;
  }
  .helpfulGuide {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .helpfulGuideMessage {
    margin-top: 2rem;
    margin-bottom: 2.4rem;
    font-size: 1.4rem;
  }
  .helpfulGuideBox {
    flex-direction: column;
  }
  .helpfulGuideLeft {
    width: 100%;
  }
  .postListItem {
    width: 90%;
    margin: 0 auto 2.4rem;
  }
  .helpfulGuideRight {
    width: 100%;
    padding: 3rem 2rem 3rem;
  }
  .voice {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .voiceMessage {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 1.4rem;
  }
  .caseSpecial {
    width: calc(100% + 3rem);
    margin: 0 -1.5rem;
    padding: 0;
  }
  .caseSpecialTop {
    margin-bottom: 0;
    padding: 2.4rem 1.5rem;
    flex-direction: column;
  }
  .caseSpecialImageWrap {
    width: 100%;
    margin-right: 0;
  }
  .caseSpecialCompany {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    font-weight: bold;
  }
  .caseSpecialHeading {
    font-size: 1.6rem;
    line-height: 3rem;
  }
  .caseSpecialBottom {
    padding-top: 5.4rem;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
  .caseSpecialBottoItem {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .caseSpecialBottoItem:not(:last-of-type) {
    margin-bottom: 4.8rem;
  }
  .caseSpecialBottoTitle {
    margin-right: 0;
    margin-bottom: 3.4rem;
    background-color: var(--grey17);
  }
  .caseListItem {
    width: 90%;
    margin: 4rem auto 0;
  }
}
