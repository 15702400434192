:root {
  --main-font: "游ゴシック体", "Yu Gothic", YuGothic,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  // --main-font: "Noto Sans JP", sans-serif;
  // --sub-font: "Roboto", sans-serif;
  --mincho-font: serif;
  --color-lightest: #f0f2f5;
  --color-light: #666;
  --shadow-primary: 0px 4px 10px rgba(37, 47, 63, 0.1);
  --shadow-secondary: 1px 1px 2px #012543;
  --shadow3: 0 0 1.6rem 0.2rem rgba(0, 0, 0, 0.1);
  --shadow4: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.12);
  --shadow5: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  --blue: #0766f5;
  --blue2: #1446de;
  --blue3: #4a8fda;
  --blue4: #001a88;
  --blue5: #004e98;
  --blue6: #e7eef4;
  --blue7: #3858f6;
  --blue8: #288bf1;
  --blue9: #001a88;
  --blue10: #0032ca;
  --blue12: #004fb5;
  --blue11: #f1f5f9;
  --blue13: #0368ff;
  --blue14: #eaf2f8;
  --prussian-blue: #012543;
  --green: #006666;
  --grey: #eee;
  --grey2: rgba(34, 34, 34, 0.12);
  --grey3: #999;
  --grey4: #ced0d4;
  --grey5: #65676b;
  --grey6: #878787;
  --grey10: #616161;
  --grey7: rgba(102, 102, 102, 0.4);
  --grey8: #ececec;
  --grey9: #d5d5d5;
  --grey12: #ccc;
  --grey11: #adb5bd;
  --grey13: #f5f5f5;
  --grey14: #efefef;
  --grey15: #d9d9d9;
  --grey16: #e7eaec;
  --grey17: #f1f1f1;
  --grey18: #6b7280;
  --grey19: #f7f5f3;
  --black2: rgba(34, 34, 34, 0.7);
  --black3: #222;
  --black4: #212529;
  --black5: #333;
  --black6: #666;
  --black7: #5f5f5f;
  --mask-black: rgba(0, 0, 0, 0.75);
  --white2: #fbfbfd;
  --white3: #fdfaf5;
  --orange: #ff6b00;
  --orange2: #f77c00;
  --red: #d70c18;
  --red2: #fd0404;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: 0.025em;
  word-break: break-all;
}

html {
  font-size: 62.5%;
}
body {
  min-height: 100vh;
  position: relative;
  font-family: var(--main-font);
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
}
main {
  flex-grow: 1;
  min-width: 0;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
}
code {
  display: block;
  padding: 1em;
  overflow-x: auto;
  background-color: #eee;
}
a {
  display: block;
  text-decoration: none;
  color: black;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.znc a {
  display: inline-block;
}
.znc {
  h1,
  h2,
  h3 {
    font-weight: bold;
  }
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
  font-family: var(--main-font);
  &:hover {
    opacity: 0.7;
  }
}
summary {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  /* display: list-item;以外を指定してデフォルトの三角形アイコンを消します */
  display: block;
  width: fit-content;
  cursor: pointer;
}

summary::-webkit-details-marker {
  /* Safariで表示されるデフォルトの三角形アイコンを消します */
  display: none;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  transform: translateZ(-1px);
}
li {
  list-style: none;
}

a,
button,
img,
iframe,
span {
  vertical-align: top;
}

input,
textarea,
select {
  vertical-align: top;
  font-family: var(--main-font);
  font-size: 1rem;
  font-weight: 500;
  background-color: #fff;
  // appearance: none;
}
input[type="text"],
input[type="email"],
select,
textarea {
  appearance: none;
  &:focus {
    outline: none;
    border-color: var(--blue4);
  }
}

option {
  background-color: white;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0 !important;
}

th,
td {
  vertical-align: middle;
}

figure {
  margin-bottom: 2rem;
}
figcaption {
  font-size: 1.2rem;
  line-height: 2rem;
}
article {
  max-width: 100%;
  min-width: 0;
}

.swiper-pagination {
  display: none;
}
.top-fv-slider .swiper-pagination {
  display: block;
}
.top-fv-slider .swiper-pagination-bullet {
  margin: 0 0.8rem !important;
}
.top-fv-slider .swiper-pagination-bullet-active::after {
  display: none;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 180%;
    height: 180%;
    left: -40%;
    top: -40%;
    border: 1px solid blue;
    border-radius: 50%;
  }
}

.znc .twitter-tweet {
  margin: 32px auto;
}

@media screen and (max-width: 600px) {
  .swiper-pagination {
    bottom: -1rem !important;
    display: block;
  }

  .swiper {
    padding-bottom: 3rem !important;
  }
}
