.c-section-title {
  span {
    display: block;
    font-weight: bold;
  }
  span:nth-of-type(1) {
    margin-bottom: 1.6rem;
    color: var(--blue9);
    font-size: 2rem;
  }
  span:nth-of-type(2) {
    color: var(--balck3);
    font-size: 4.8rem;
  }
}

.c-contact-banners {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  a {
    width: 35.5rem;
    height: 9rem;
    color: white !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 0.3rem;
    & > span:nth-of-type(1) {
      font-size: 1rem;
    }
    & > span:nth-of-type(2) {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: bold;
    }
  }
  a:nth-of-type(1) {
    margin-right: 2rem;
    background-color: var(--orange2);
    img {
      width: 2.7rem;
      margin: 0 0.6rem 0 0;
    }
    & > span:nth-of-type(2) {
      font-size: 2.7rem;
      padding-bottom: 0.4rem;
    }
  }
  a:nth-of-type(2) {
    background-color: var(--blue8);
    img {
      width: 3.3rem;
      margin: 0 0.6rem 0 0;
    }
    & > span:nth-of-type(2) {
      margin-top: 1rem;
      font-size: 1.3rem;
    }
  }
}

.c-title-wrap {
  text-align: center;
  .en-title {
    margin-bottom: 1.4rem;
    font-size: 2rem;
    font-weight: bold;
  }
  .ja-title {
    font-size: 4.8rem;
    font-weight: bold;
  }
  .text {
    margin-top: -0.8rem;
    font-weight: 300;
  }
}

.c-title-wrap2 {
  text-align: center;
  .en-title {
    margin-bottom: 1.4rem;
    font-size: 2rem;
    font-weight: bold;
    color: var(--blue4);
  }
  .ja-title {
    font-size: 4.8rem;
    font-weight: bold;
  }
}

.c-image-wrap {
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.c-details {
  width: 46.8rem;
  padding: 0 1.6rem;
  background-color: white;
  line-height: 5.5rem;
  border: 1px solid;
  border-radius: 0.4rem;
  color: var(--blue9);
  &:not(:first-of-type) {
    margin-left: 2.4rem;
  }
  button,
  a {
    width: 100%;
    display: block;
    border-bottom: 1px solid;
    line-height: 5.5rem;
    position: relative;
    text-align: left;
    font-weight: bold;
    color: var(--blue9);
    &::after {
      content: "";
      position: absolute;
      right: 0.4rem;
      top: 2.2rem;
      width: 1rem;
      height: 1rem;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
  li:first-of-type {
    button,
    a {
      border-top: 1px solid;
    }
  }
  li:last-of-type {
    button,
    a {
      border-bottom: none;
    }
  }
  summary {
    width: 100%;
    position: relative;
    text-align: center;
    font-weight: bold;
    &::after {
      content: "";
      position: absolute;
      right: 0.4rem;
      top: 2.2rem;
      width: 1rem;
      height: 1rem;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(135deg);
    }
  }
}

.c-details[open] {
  summary::after {
    transform: translateY(0.4rem) rotate(-45deg);
  }
}

.c-form-list-item-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .c-form-list-item {
    width: calc(50% - 0.4rem);
  }
}

.c-form-list-item {
  width: 100%;
  margin-bottom: 2rem;
  &.half {
    width: calc(50% - 0.4rem);
  }
}

.c-form-list-title {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: flex-start;
  font-size: 1.3rem;
}

.c-form-list-caution {
  font-size: 1.3rem;
}

.c-form-required {
  padding: 0 0.4rem;
  margin-right: 0.8rem;
  background-color: var(--red);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  transform: translateY(0.2rem);
  flex-shrink: 0;
}

.c-last-checkbox-item {
  margin-bottom: 1rem;
}

.c-validation-error {
  margin-top: 0.3rem;
  font-size: 1.3rem;
  color: var(--red);
}

.c-checkbox-label {
  font-size: 1.3rem;
  display: flex;
  align-items: flex-start;
  vertical-align: middle;

  .small {
    font-size: 1.1rem;
    vertical-align: baseline;
  }
}

.c-last-checkbox-label {
  font-size: 1.6rem;
  display: flex;
  align-items: flex-start;
  input {
    transform: translateY(0.1rem);
  }
}

.c-input {
  width: 100%;
  height: 4rem;
  border-radius: 0.4rem;
  border: 1px solid var(--grey12);
  line-height: 4rem;
  padding: 0 1.8rem;
  font-size: 1.6rem;
  &:focus {
    outline: none;
    border-color: var(--blue4);
  }
  &::placeholder {
    color: var(--grey11);
  }
}

.c-select-wrap {
  position: relative;
  background-color: white;
  &::after {
    content: "";
    position: absolute;
    color: var(--grey11);
    right: 2rem;
    top: 1.4rem;
    width: 1rem;
    height: 1rem;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
  }
  &.is-empty {
    background-color: var(--grey14);
  }
}

.c-select {
  width: 100%;
  height: 4rem;
  padding-right: 3rem;
  border-radius: 0.4rem;
  border: 1px solid var(--grey12);
  line-height: 4rem;
  padding: 0 4rem 0 1.8rem;
  font-size: 1.6rem;
  -webkit-appearance: none;
  appearance: none; /* デフォルトの矢印を非表示 */
  position: relative;
  z-index: 2;
  background-color: transparent;
  &:focus {
    outline: none;
    border-color: var(--blue4);
  }
}

.c-textarea-wrap {
  position: relative;
  background-color: white;
  z-index: 2;
  textarea {
    width: 100%;
    height: 8.5rem;
    border-radius: 0.4rem;
    border: 1px solid var(--grey12);
    padding: 0.4rem 1.8rem;
    font-size: 1.6rem;
    // opacity: 0.7;
    background-color: transparent;
    &:focus {
      outline: none;
      border-color: var(--blue4);
    }
  }
}

.c-textarea-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.4rem 1.8rem;
  color: var(--grey11);
  z-index: -1;
}

.c-privacy-link-text {
  margin-top: 0.4rem;
  font-size: 1.1rem;
  padding-left: 2rem;
  a {
    display: inline;
    text-decoration: underline;
    color: var(--blue10);
  }
}

.c-button-submit {
  width: 100%;
  margin-top: 2.6rem;
  line-height: 5.2rem;
  border-radius: 2.6rem;
  font-weight: bold;
  color: white;
  background-color: var(--blue10);
  font-size: 1.6rem;
}

.znc .c-link-card {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  color: black;
  border: 1px solid #ddd;
  border-radius: 0.4rem;
  text-decoration: none !important;

  & > div:nth-of-type(1) {
    width: 70%;
    padding: 1rem 1.6rem;
  }
  & > div:nth-of-type(2) {
    width: 30%;
    min-width: 25rem;
    display: flex;
    flex-direction: column;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.4rem;
    &:first-of-type {
      margin-bottom: 0.5rem;
      font-size: 1.6rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &:nth-of-type(2) {
      color: rgba(55, 53, 47, 0.65);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &:last-of-type {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  img {
    margin: 0 !important;
    height: 100% !important;
  }
}

.znc {
  max-width: 84rem;
}

.c-original-table-wrap {
  width: 100%;
  margin: 3rem auto;
  overflow: auto;
}

.c-original-table {
  font-size: 1.1rem !important;
  border-left: 1px solid #d6e3ed;
  border-top: 2px solid #a7d0fb;
  img {
    margin: 0 auto !important;
  }
  .site-link {
    display: block;
    width: fit-content;
    margin: 0 auto;
    color: white;
    background-color: #0f83fd;
    line-height: 4rem;
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
    text-decoration: none;
    box-shadow: var(--shadow3);
  }
  thead {
    white-space: nowrap;
    border-bottom: 1px solid #ddd;
    tr:nth-of-type(1) {
      th:last-of-type {
        background-color: #d9e4ef;
        text-align: left;
        padding-left: 1.2rem;
      }
    }
    tr:nth-of-type(2) {
      th {
        background-color: #e8ecf0 !important;
      }
    }
  }
  tr:nth-of-type(even) {
    background-color: #fbfbfb;
    th:nth-of-type(1),
    td:nth-of-type(1) {
      background-color: #fbfbfb;
    }
  }
  tr {
    border-bottom: 1px solid #eee;
  }

  th,
  td {
    background-color: transparent;
    padding: 0.8rem;
    border: 1px solid #eee;
    border-top: none;
    border-bottom: none;
  }
  tr:nth-of-type(1) {
    th:nth-of-type(1) {
      min-width: 10rem;
      max-width: 10rem;
      position: sticky;
      left: 0;
      border-left: none;
      border-right: none;
      z-index: 2;
      background-color: white;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0.2rem;
        width: 0.1rem;
        background-color: #d6e3ed;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 0.1rem;
        background-color: #d6e3ed;
      }
    }

    th:nth-of-type(2) {
      min-width: 8rem;
      max-width: 8rem;
      border-left: none;
    }
  }

  td:nth-of-type(1) {
    min-width: 10rem;
    max-width: 10rem;
    position: sticky;
    left: 0;
    border-left: none;
    border-right: none;
    z-index: 2;
    background-color: white;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.2rem;
      width: 0.1rem;
      background-color: #d6e3ed;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 0.1rem;
      background-color: #d6e3ed;
    }
  }

  td:nth-of-type(2) {
    min-width: 8rem;
    max-width: 8rem;
    border-left: none;
  }
  td:nth-of-type(3) {
    min-width: 4rem;
    max-width: 4rem;
  }
  td:nth-of-type(3) {
    text-align: center;
  }
  td:nth-of-type(4) {
    min-width: 8rem;
    max-width: 8rem;
  }
  td:nth-of-type(5) {
    min-width: 14rem;
    max-width: 14rem;
  }
  td:nth-of-type(6) {
    min-width: 7rem;
    max-width: 7rem;
  }
  td:nth-of-type(7) {
    min-width: 10rem;
    max-width: 10rem;
  }
  td:nth-of-type(8) {
    min-width: 10rem;
    max-width: 10rem;
  }
  td:nth-of-type(9) {
    min-width: 10rem;
    max-width: 10rem;
  }
  td:nth-of-type(10) {
    min-width: 5rem;
    max-width: 5rem;
  }
  td:nth-of-type(11) {
    min-width: 8rem;
    max-width: 8rem;
  }
  td:nth-of-type(12) {
    min-width: 12rem;
    max-width: 12rem;
  }
  td:nth-of-type(13) {
    min-width: 12rem;
    max-width: 12rem;
  }
  td:nth-of-type(14),
  td:nth-of-type(15),
  td:nth-of-type(16) {
    min-width: 12rem;
    max-width: 12rem;
  }
}

.znc .list-card-list {
  padding-left: 0;
}

.znc .list-card {
  border: 1px solid #eee;
  border-radius: 0.8rem;
  margin-bottom: 3rem;
  padding: 2rem;
  list-style: none;

  .open-section {
    display: flex;
    flex-direction: column-reverse;
  }

  .open-check {
    display: none;
  }

  .open-label {
    margin-top: 1.6rem;
    cursor: pointer;
    font-size: 1.4rem;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: "";
      margin-left: 1.2rem;
      width: 0.7rem;
      height: 0.7rem;
      border-top: 1px solid #999;
      border-right: 1px solid #999;
      transform: translateY(-0.3rem) rotate(135deg);
    }
  }

  .open-check:checked + .open-label {
    display: none;
  }

  .open-check:checked + .open-label + dl {
    div {
      display: flex !important;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1.3rem;
    line-height: 1.5;
    & > div {
      width: 48%;
      margin-bottom: 1rem;
      display: flex;
      border-bottom: 1px solid #e7e7e7;
      padding: 0.6rem 0;
      &:nth-of-type(n + 5) {
        display: none;
      }
    }
  }
  dt {
    width: 43%;
    margin-right: 10%;
    color: #666;
    flex-shrink: 0;
  }

  .swiper {
    position: relative;
  }
  .swiper-pagination {
    display: block;
    bottom: 0;
  }
  .swiper-pagination-bullet-active {
    background-color: #666;
    &::after {
      display: none;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 4.4rem;
    border-radius: 50%;
    color: #666;
    background-color: #f7f7ff;
    top: calc(50% + 1.1rem);
    transform: scale(0.7);
    &::after {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
  .swiper-slide {
    position: relative;
    padding-top: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      object-fit: contain;
    }
  }
}
.znc .list-card-title {
  font-size: 2rem;
}
.znc .list-card-rnak {
  font-size: 1.8rem;
  text-align: center;
  background-color: #eee;
  min-width: 6rem;
  display: table;
  font-weight: bold;
}
.znc .list-card-top {
  display: flex;
  align-items: flex-start;
  & > div {
    width: 50%;
    overflow: hidden;
  }
  ul {
    padding-left: 0;
    display: flex;
  }
  li {
    min-width: 100%;
    list-style: none;
    padding-top: 100%;
    position: relative;
  }
  a {
    width: 40%;
    margin-left: 10%;
    line-height: 6.4rem;
    font-weight: bold;
    text-align: center;
    background-color: #0f83fd;
    color: white;
    box-shadow: var(--shadow3);
    border-radius: 1rem;
    text-decoration: none !important;
  }
}

.znc .list-card-point {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (max-width: 1000px) {
  .znc .list-card-top {
    flex-direction: column;
    a {
      margin-left: 0;
      margin-top: 2rem;
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .c-contact-banners {
    flex-direction: column;
    a {
      max-width: 40rem;
      width: 100%;
      height: 7rem;
      margin: 0.8rem auto !important;
    }
    a:nth-of-type(1) {
      margin-right: 4%;
      span:nth-of-type(2) {
        font-size: 2.2rem;
      }
    }
    a:nth-of-type(2) {
      span:nth-of-type(2) {
        margin-top: 0.4rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .c-section-title {
    text-align: left;
    span:nth-of-type(1) {
      margin-bottom: 0.4rem;
      font-size: 1.6rem;
    }
    span:nth-of-type(2) {
      font-size: 3.2rem;
    }
  }
  .c-title-wrap {
    text-align: left;
    padding: 0 1.5rem;
    .en-title {
      display: none;
    }
    .ja-title {
      font-size: 2.5rem;
    }
    .text {
      margin-top: 0;
      font-size: 1.2rem;
      font-weight: 400;
    }
  }
  .c-title-wrap2 {
    text-align: left;
    padding: 0 1.5rem;
    .en-title {
      display: none;
    }
    .ja-title {
      font-size: 2.5rem;
    }
  }
  .c-button-submit {
    font-size: 1.4rem;
    line-height: 4.4rem;
    border-radius: 2.2rem;
  }

  .c-details {
    font-size: 1.2rem;
    padding: 0 0.8rem;
    &:not(:first-of-type) {
      margin-left: 1.6rem;
    }

    summary {
      text-align: left;
      line-height: 4.4rem;
      &::after {
        width: 0.8rem;
        height: 0.8rem;
        top: 1.6rem;
      }
    }
    button {
      line-height: 4.4rem;
      &::after {
        width: 0.8rem;
        height: 0.8rem;
        top: 1.8rem;
      }
    }
  }

  .znc .c-link-card {
    flex-direction: row-reverse;
    box-shadow: var(--shadow-primary);

    & > div:nth-of-type(1) {
      width: 60%;
      display: flex;
      align-items: center;
    }
    & > div:nth-of-type(2) {
      width: 40%;
      min-width: auto;
      padding: 0.5rem;
      justify-content: center;
    }
    p {
      &:first-of-type {
        -webkit-line-clamp: 3;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 0;
      }
      &:nth-of-type(2) {
        display: none;
      }

      &:last-of-type {
        display: none;
      }
    }
  }

  .znc .list-card-top {
    & > div {
      width: 100%;
    }
    a {
      margin-left: 0;
      margin-top: 0;
      width: 100%;
      line-height: 4.8rem;
    }
  }

  .znc .list-card dl > div {
    width: 100%;
  }
  .znc .list-card dt {
    width: 30%;
    margin-right: 8%;
  }
}

.c-check-icon {
  width: 1.9rem;
  height: 1.9rem;
  padding-bottom: 0.4rem;
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    width: 1.4rem;
    height: 0.7rem;
    border-left: 1px solid var(--blue2);
    border-bottom: 1px solid var(--blue2);
    transform: rotate(-45deg);
  }
}

.c-peke-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    width: 1.5rem;
    height: 0.1rem;
    background-color: #b14c4c;
    transform: rotate(-45deg);
  }
  &::after {
    content: "";
    width: 1.5rem;
    height: 0.1rem;
    margin-top: -0.1rem;
    background-color: #b14c4c;
    transform: rotate(45deg);
  }
}
