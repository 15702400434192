.u-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.u-fill-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.u-text-center {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .u-doubled-image {
    position: absolute;
    width: 200%;
    max-width: none;
    top: -50%;
    left: -50%;
    transform: scale(0.5);
  }
}
